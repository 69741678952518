<template>
  <div class="ibox modules">
    <div class="ibox-title">
      <h2>{{ getLocalizedText(labels.title) }}</h2>
    </div>
    <div :class="'ibox-content p-md'">

      <form role="form" @submit="onFormSubmit" name="update-client-settings">
        <b-table 
            responsive
            outlined striped
            :items="form.countryConfigs"
            :fields="countryConfigsFields"
            ref="countryConfigs">
          <template v-slot:cell(countryCode)="row">
            <TextField 
              v-bind:value.sync="row.item.countryCode" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(label)="row">
            <TextField 
              v-bind:value.sync="row.item.label" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(compteNumber)="row">
            <TextField 
              v-bind:value.sync="row.item.compteNumber" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(compteVATNumber)="row">
            <TextField 
              v-bind:value.sync="row.item.compteVATNumber" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(defaultVATPercent)="row">
            <TextField 
              v-bind:value.sync="row.item.defaultVATPercent" 
              fieldType="number"
              :step="0.001"
              placeholder="" 
              :required="false"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(isUE)="row">
            <SwitchCheck 
              v-bind:value.sync="row.item.isUE" 
              :required="false"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(taxableJuridictionValue)="row">
            <TextField 
              v-bind:value.sync="row.item.taxableJuridictionValue" 
              placeholder="" 
              :required="false"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(options)="row">
            <a href="javascript:void(0)" @click="removeCountryConfig(row.index)"><i class="fa fa-trash-o"></i></a>
          </template>
        </b-table>
        <button class="btn btn-primary" type="button" @click="addCountryConfig()">{{ getLocalizedText(labels.addCountryConfigButton) }}</button>

        <hr/>

        <b-table 
            responsive
            outlined striped
            :items="form.marketplaceConfigs"
            :fields="marketplaceConfigsFields"
            ref="marketplaceConfigs">
          <template v-slot:cell(marketplace)="row">
            <TextField 
              v-bind:value.sync="row.item.marketplace" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(compteNumber)="row">
            <TextField 
              v-bind:value.sync="row.item.compteNumber" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(countryCode)="row">
            <TextField 
              v-bind:value.sync="row.item.countryCode" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
          <template v-slot:cell(options)="row">
            <a href="javascript:void(0)" @click="removeMarketplaceConfig(row.index)"><i class="fa fa-trash-o"></i></a>
          </template>
        </b-table>
        <button class="btn btn-primary" type="button" @click="addMarketplaceConfig()">{{ getLocalizedText(labels.addMarketplaceConfigButton) }}</button>

        <hr/>

        <b-table 
            responsive
            outlined striped
            :items="form.compteConfigs"
            :fields="compteConfigsFields"
            ref="compteConfigs">
          <template v-slot:cell(key)="row">
            {{ row.item.key }}
          </template>
          <template v-slot:cell(compteNumber)="row">
            <TextField 
              v-bind:value.sync="row.item.compteNumber" 
              placeholder="" 
              :required="true"
              :labelClass="{'hidden':true}"
            />
          </template>
        </b-table>

        <hr/>

        <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.updateButton) }}</button>
      </form>
    </div>
  </div>
</template>

<style>
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { useModuleAdmin } from '../../../composables/useModuleAdmin';

export default defineComponent({
  props: {
    module: {
        type: Object,
        required: true
      }
  },
  components: {
    TextField,
    Select,
    SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { getClientSettings, updateClientSettings } = useModuleAdmin(props, context);

    const availableLanguageCodes = ['fr','en'];

    const { module } = toRefs(props);

    /**
     * countryConfigs:CountryConfigs,
     * marketplaceConfigs:MarketplaceConfigs,
     * compteConfigs:CompteConfigs
     */

    const clientSettings:Ref<any> = ref({});
    const form:Ref<any> = ref({
      countryConfigs : [],
      marketplaceConfigs : [],
      compteConfigs : []
    });
    getClientSettings(module.value.module).then((result:any) => {
      clientSettings.value = result.clientSettings;
      updateFormFromClientSettings(result.clientSettings);
    });

    const countryConfigs:Ref<HTMLElement|null> = ref(null);
    const marketplaceConfigs:Ref<HTMLElement|null> = ref(null);
    const compteConfigs:Ref<HTMLElement|null> = ref(null);


    const labels = {
      "title": {
        "fr" : "Paramètres",
        "en" : "Settings"
      },
      "addCountryConfigButton" : {
        "fr" : "Ajouter un pays",
        "en" : "Add a country"
      },
      "addMarketplaceConfigButton" : {
        "fr" : "Ajouter un marketplace",
        "en" : "Add a marketplace"
      },
      "updateButton" : {
        "fr" : "Sauvegarder",
        "en" : "Save"
      }
    }

    const countryConfigsFields = [
      {
        key: "countryCode",
        label: "Code Pays"
      },
      {
        key: "label",
        label: "Libellé"
      },
      {
        key: "compteNumber",
        label: "Numéro de compte"
      },
      {
        key: "compteVATNumber",
        label: "Numéro de TVA"
      },
      {
        key: "defaultVATPercent",
        label: "TVA par défaut"
      },
      {
        key: "isUE",
        label: "UE"
      },
      {
        key: "taxableJuridictionValue",
        label: "Taxable Juridiction"
      },
      {
        key: "options",
        label: "Options"
      }
    ];

    const marketplaceConfigsFields = [
      {
        key: "marketplace",
        label: "URL marketplace"
      },
      {
        key: "compteNumber",
        label: "Numéro de compte"
      },
      {
        key: "countryCode",
        label: "Code Pays"
      },
      {
        key: "options",
        label: "Options"
      }
    ];

    const compteConfigsFields = [
      {
        key: "key",
        label: "Clé"
      },
      {
        key: "compteNumber",
        label: "Numéro de compte"
      }
    ];

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-client-settings] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    const updateFormFromClientSettings = (settings:any) => {
      if(settings && settings.countryConfigs != undefined) {
        var countryCodes = Object.keys(settings.countryConfigs);
        var countryConfigs = countryCodes.map((countryCode) => {
          var countryConfig = settings.countryConfigs[countryCode];
          return {
            countryCode : countryCode,
            label : countryConfig.label,
            compteNumber : countryConfig.compteNumber,
            compteVATNumber : countryConfig.compteVATNumber,
            defaultVATPercent : countryConfig.defaultVATPercent,
            isUE : countryConfig.isUE,
            taxableJuridictionValue : countryConfig.taxableJuridictionValue
          }
        })
        form.value.countryConfigs = countryConfigs;
      }

      if(settings && settings.marketplaceConfigs != undefined) {

        var marketplaces = Object.keys(settings.marketplaceConfigs);
        var marketplaceConfigs = marketplaces.map((marketplace) => {
          var marketplaceConfig = settings.marketplaceConfigs[marketplace];
          return {
            marketplace : marketplace,
            compteNumber : marketplaceConfig.compteNumber,
            countryCode : marketplaceConfig.countryCode
          }
        })

        form.value.marketplaceConfigs = marketplaceConfigs;
      }

      var compteConfigsKeys = [
        "VENTES_FRANCE",
        "VENTES_FRANCE_HUE",
        "VENTES_FRANCE_BTOB_UE",
        "VAT_VENTES_FRANCE",
        "AUTRES_PAYS_TAXATION",
        "DONATION_CREDIT",
        "DONATION_DEBIT",
        "COMMINGLING_BUY_COUNTRY",
        "COMMINGLING_SELL_COUNTRY",
        "VAT_COMMINGLING",
        "VAT_DUE_EXTRACOM_FR",
        "VAT_EXTRACOM_FR",
        "VAT_DUE_EXTRACOM",
        "VAT_EXTRACOM",
        "COMMINGLING_BUY",
      ]
      var compteConfigs = [];
      for(var compteConfigsKey of compteConfigsKeys) {
        if(settings && settings.compteConfigs != undefined && settings.compteConfigs[compteConfigsKey] != undefined) {
          var compteConfig = settings.compteConfigs[compteConfigsKey];
          compteConfigs.push({
            key : compteConfigsKey,
            compteNumber : compteConfig
          })
        }
        else {
          compteConfigs.push({
            key : compteConfigsKey,
            compteNumber : ""
          })
        }
      }
      form.value.compteConfigs = compteConfigs;
    }

    const getSettingsFormForm = () => {
      let settings = {
        countryConfigs: form.value.countryConfigs.reduce((acc:any, countryConfig:any) => {
          acc[countryConfig.countryCode] = {
            label: countryConfig.label,
            compteNumber: countryConfig.compteNumber,
            compteVATNumber: countryConfig.compteVATNumber,
            defaultVATPercent: countryConfig.defaultVATPercent,
            isUE: countryConfig.isUE,
            taxableJuridictionValue: countryConfig.taxableJuridictionValue
          }
          return acc;
        }, {}),
        marketplaceConfigs: form.value.marketplaceConfigs.reduce((acc:any, marketplaceConfig:any) => {
          acc[marketplaceConfig.marketplace] = {
            compteNumber: marketplaceConfig.compteNumber,
            countryCode: marketplaceConfig.countryCode
          }
          return acc;
        }, {}),
        compteConfigs: form.value.compteConfigs.reduce((acc:any, compteConfig:any) => {
          acc[compteConfig.key] = compteConfig.compteNumber;
          return acc;
        }, {})
      }
      return settings;
    }

    const addCountryConfig = () => {
      form.value.countryConfigs.push({
        countryCode : "",
        label : "",
        compteNumber : "",
        compteVATNumber : "",
        defaultVATPercent : 0,
        isUE : false,
        taxableJuridictionValue : ""
      });
      // @ts-ignore
      countryConfigs.value.refresh()
    }

    const removeCountryConfig = (indexCountryConfig:number) => {
      form.value.countryConfigs.splice(indexCountryConfig, 1);
      // @ts-ignore
      countryConfigs.value.refresh()
    }

    const addMarketplaceConfig = () => {
      form.value.marketplaceConfigs.push({
        marketplace : "",
        compteNumber : "",
        countryCode : ""
      });
      // @ts-ignore
      marketplaceConfigs.value.refresh()
    }

    const removeMarketplaceConfig = (indexMarketplaceConfig:number) => {
      form.value.marketplaceConfigs.splice(indexMarketplaceConfig, 1);
      // @ts-ignore
      marketplaceConfigs.value.refresh()
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      let settingsInput = getSettingsFormForm();
      laddaSubmit!.start();
      updateClientSettings(module.value.module, settingsInput).then((result:any) => {
        if(result.updated) {
          context.emit('module-updated', result.module);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      form,
      labels,
      onFormSubmit,
      countryConfigsFields,
      addCountryConfig,
      removeCountryConfig,
      marketplaceConfigsFields,
      addMarketplaceConfig,
      removeMarketplaceConfig,
      compteConfigsFields,
      marketplaceConfigs,
      countryConfigs,
      compteConfigs
    }
  }  
})
</script>