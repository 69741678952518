import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as moduleTypes } from '../store';

import { roles as moduleRoles } from '@igotweb-node-api/api/modules/amazon/src/roles/index';
import { GenericModuleAdminInput, useGenericModuleAdmin } from "@root/src/client/composables/admin/useGenericModuleAdmin";

export const apiModulePath = '/api/admin/amazon';

export const genericModuleInput:GenericModuleAdminInput = {
  apiModulePath: apiModulePath,
  storeModule: 'amazon',
  storeModuleTypes: moduleTypes
}

interface ModuleAdminInput {
}

export function useModuleAdmin(props:ModuleAdminInput, context:any) { 
  const app = getApp();
  const store = useStore();

  const { 
    callModuleAdmin,
    emitModuleAdmin,
    isListLoading,
    modules,
    getModuleFromID,
    selectedModule,
    selectModule,
    moduleForm,
    createModule,
    updateModule,
    removeModule,
    isModuleUpdateAllowed,
    isModuleCreateAllowed,
    isModuleRemoveAllowed,
    updateModuleFormForUpdate,
    getAdminSettings,
    updateAdminSettings,
    getClientSettings,
    updateClientSettings } = useGenericModuleAdmin(genericModuleInput, context);

  // We can add specific module logic

  // We need to get access to the list of storage accounts
  const getStorageAccounts = async (module:any) => {
    var result:any = {
      storageAccounts: []
    }
    try {
      var response = await callModuleAdmin('/'+module._id+'/settings/storage-accounts');
      if(response.storageAccounts) {  
        result.storageAccounts = response.storageAccounts;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  // We need to get access to the list of vat validation modules
  const getVATValidationModules = async (module:any) => {
    var result:any = {
      vatValidationModules: []
    }
    try {
      var response = await callModuleAdmin('/'+module._id+'/settings/vat-validation-modules');
      if(response.modules) {  
        result.vatValidationModules = response.modules;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }




  return {
    callModuleAdmin,
    emitModuleAdmin,
    isListLoading,
    modules,
    getModuleFromID,
    selectedModule,
    selectModule,
    moduleForm,
    createModule,
    updateModule,
    removeModule,
    isModuleUpdateAllowed,
    isModuleCreateAllowed,
    isModuleRemoveAllowed,
    updateModuleFormForUpdate,
    getAdminSettings,
    updateAdminSettings,
    getClientSettings,
    updateClientSettings,
    moduleRoles,
    getStorageAccounts,
    getVATValidationModules
  }
  
}